:root {
  /* Colors */
  --black: #000000;
  --dark: #231f20;
  --white: #ffffff;
  --red: #c3281e;
  --red-with-alpha: #c3281e00;
  --yellow: #ffbf00;
  --light-yellow: #fdfbf2;
  --dark-gray: #242424;
  --gray: #3b3b3b;
  --green: #006f3c;
  --dark-green: #004218;
  --darker-green: #003322;
  --opaque-green: #048b4d;
  --leaf-green: #045037;
  --soft-gray: #626262;
  --light-gray: #707070;
  --opaque-red: #8b140c;
  --wine-red: #6c0e08;
  --darker-red: #480a06;
  
  /* General */
  --primary: var(--red);
  --primary-with-alpha: var(--red-with-alpha);
  --secondary: var(--white);
  --primary-bg: var(--primary);
  --secondary-bg: var(--black);
  --primary-text: var(--black);
  --secondary-text: var(--white);
  --accent: var(--white);
  
  /*  Buttons */
  --btn-primary-bg: var(--primary);
  --btn-secondary-bg: var(--secondary-bg);
  --btn-primary-txt: var(--secondary-text);
  --btn-secondary-txt: var(--white);

  /* Modal */
  --modal-title: var(--red);
  --modal-description: var(--primary-text);
  --modal-bg-1: var(--primary);
  --modal-bg-2: var(--secondary);
  --modal-btn-text-1: var(--secondary-text);
  --modal-btn-bg-1: var(--primary);
  --modal-btn-text-2: var(--secondary);
  --modal-btn-bg-2: var(--secondary-bg);

  /* Header */
  --header-menu-tooltip: var(--secondary);

  /* Banner */
  --banner-title: var(--secondary-text);
  --banner-description: var(--secondary-text);
  --banner-progress-bar: var(--black);

  /* Próximo Jogo */
  --next-match-txt: var(--dark-green);

  /* Mensalidade */
  --mensalidade-border-box: var(--secondary-bg);

  /* Beneficio Home */

  --beneficio-title-color: var(--primary-text);
  --beneficio-txt-color: var(--primary-text);
  /* Beneficios */
  --beneficios-box-1: var(--opaque-red);
  --beneficios-box-2: var(--wine-red);
  --beneficios-box-3: var(--darker-red);
  --beneficios-info-mobile: var(--black);

  /* Parceiros */

  --parceiro-btn-bg: var(--primary-text);
  --parceiro-header-txt: var(--primary--text);

  /* Form */
  --search-icon-bg: var(--secondary-bg);
  --search-icon-txt: var(--accent);
  --search-icon-bg-secondary: var(--primary);
  --search-icon-txt-secondary: var(--secondary-bg);
  --input-bg: var(--light-yellow);
  --error-icon-bg-primary: var(--white);
  --error-icon-bg-secondary: var(--primary);
  --error-icon-txt-primary: var(--black);
  --error-icon-txt-secondary: var(--secondary-text);
  --error-msg-bg: var(--white);
  --error-msg-bg-secondary: var(--primary);
  --error-msg-txt: var(--black);
  --error-msg-txt-secondary: var(--white);
  --error-icon-primary: var(--primary);
  --error-icon-secondary: var(--black);
  
  /* Contato */
  --search-icon-bg-secondary: var(--primary);
  --search-icon-txt-secondary: var(--btn-primary-txt);
  --error-icon-txt-secondary: var(--primary-text);
}

div#root {
  overflow-x: hidden;    
}
